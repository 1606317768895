//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralEditWithTree from '@/components/GeneralEditWithTree';
import GeneralEditTree from '@/components/GeneralEditTree';
export default {
  name: 'Index',
  components: {
    GeneralEditWithTree: GeneralEditWithTree,
    GeneralEditTree: GeneralEditTree
  },
  data: function data() {
    return {
      id: this.$route.params.id,
      rules: {
        b_organization_code: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'blur'
        }, {
          min: 0,
          max: 50,
          message: this.$t("rules.common.maxlength50"),
          trigger: 'blur'
        }],
        b_organization_name: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'blur'
        }, {
          min: 0,
          max: 100,
          message: this.$t("rules.common.maxlength100"),
          trigger: 'blur'
        }],
        b_organization_abbr: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'blur'
        }, {
          min: 0,
          max: 50,
          message: this.$t("rules.common.maxlength50"),
          trigger: 'blur'
        }],
        b_organization_type: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'change'
        }],
        b_organization_status: [{
          required: true,
          message: this.$t("rules.common.required"),
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    url: function url() {
      if (this.$route.params.id) {
        return {
          getUrl: '/organization',
          method: 'put'
        };
      } else {
        return {
          getUrl: '/organization',
          method: 'post'
        };
      }
    }
  },
  methods: {
    handleDataChanged: function handleDataChanged() {
      this.$refs.GeneralEditTree.getTreeData();
    },
    handleNodeClick: function handleNodeClick(id) {
      this.$refs.GeneralEditWithTree.initData(id);
    }
  }
};