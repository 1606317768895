var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edit-container" }, [
    _c("div", { staticClass: "left-side" }, [
      _c("div", { staticClass: "left-side-top" }, [
        _vm._v("\n      " + _vm._s(_vm.$t("route.organization")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "left-side-main" },
        [
          _c("GeneralEditTree", {
            ref: "GeneralEditTree",
            attrs: {
              "tree-key": "value",
              "tree-label": "label",
              "tree-url": "/organization/tree",
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right-side" },
      [
        _c("GeneralEditWithTree", {
          ref: "GeneralEditWithTree",
          attrs: {
            "table-name": "t_organization",
            "row-key": "c_id",
            url: _vm.url,
            id: _vm.id,
            rules: _vm.rules,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }